import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaButton = ({ platform, link, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  const renderButton = () => {
    switch (platform) {
      case "facebook":
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faFacebook} style={iconStyle} />
          </a>
        );
      case "twitter":
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faXTwitter} style={iconStyle} />
          </a>
        );
      case "instagram":
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faInstagram} style={iconStyle} />
          </a>
        );
      case "linkedin":
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faLinkedin} style={iconStyle} />
          </a>
        );
      case "tiktok":
        return (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="icon-button"
            style={buttonStyle}
          >
            <FontAwesomeIcon icon={faTiktok} style={iconStyle} />
          </a>
        );
      default:
        return null;
    }
  };

  return <div className="social-button">{renderButton()}</div>;
};

export default SocialMediaButton;
