import React, { useState } from "react";
import Button from "../buttons/Button";
import ShareModal from "../modal/ShareModal";
import "./contactInfo.css";

const ContactInfo = ({ buttons, slogan, slug, colors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const vcfFileName = `${slug}.vcf`;
  // const profileLink = `https://mitedi.com/${slug}`;
  const profileLink = `https://tedi.website/${slug}`;

  const shareData = buttons.find((button) => button.type === "share");
  const buttonColors = colors.buttons;
  const topStyle = {
    backgroundColor: colors.background.top,
  };
  const bottomStyle = {
    backgroundColor: colors.background.bottom,
  };
  const descriptionStyle = {
    backgroundColor: colors.background.bottom,
    color: colors.text.slogan,
  };

  // remove buttons without value
  const validButtons = buttons.filter((button) => button.value);
  const totalButtons = validButtons.length;
  const midIndex = Math.floor(totalButtons / 2);
  const topRowButtons = validButtons.slice(0, midIndex);
  const bottomRowButtons = validButtons.slice(midIndex);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="contact-info" style={topStyle}>
      <div className="icon-row first-row">
        {topRowButtons.map((button) => (
          <Button
            key={button.id}
            type={button.type}
            value={button.value}
            buttonColors={buttonColors}
            slug={slug}
            onClick={button.type === "share" ? toggleModal : null}
            vcfFileName={button.type === "vcfFile" ? vcfFileName : null}
          />
        ))}
      </div>
      <div className="icon-row second-row" style={bottomStyle}>
        {bottomRowButtons.map((button) => (
          <Button
            key={button.id}
            type={button.type}
            value={button.value}
            buttonColors={buttonColors}
            slug={slug}
            onClick={button.type === "share" ? toggleModal : null}
            vcfFileName={button.type === "vcfFile" ? vcfFileName : null}
          />
        ))}
        <ShareModal
          image={shareData.value.qrCode}
          toggleModal={toggleModal}
          isOpen={isOpen}
          link={profileLink}
        />
      </div>
      <div className="description" style={descriptionStyle}>
        <p>{slogan}</p>
      </div>
    </div>
  );
};

export default ContactInfo;
