import React from "react";
import "./cardHeader.css";

const CardHeader = ({ banner, avatar, name, jobPosition, colors }) => {
  const nameStyle = {
    color: colors.text.name,
  };
  const jobPositionStyle = {
    color: colors.text.jobPosition,
  };
  const topStyle = {
    backgroundColor: colors.background.top,
  };
  return (
    <div className="card-header" style={topStyle}>
      <div className="logo-banner">
        <img src={banner} alt={`${name} banner pic`} className="logo" />
      </div>
      <img src={avatar} alt={`${name} profile pic`} className="profile-image" />
      <h1 className="name" style={nameStyle}>
        {name}
      </h1>
      <h2 className="job-position" style={jobPositionStyle}>
        {jobPosition}
      </h2>
    </div>
  );
};

export default CardHeader;
