import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const EmailButton = ({ email, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  const { address, subject, message } = email;
  const handleClick = () => {
    const mailtoUrl = `mailto:${address}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoUrl;
  };

  return (
    <button className="icon-button" onClick={handleClick} style={buttonStyle}>
      <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
    </button>
  );
};

export default EmailButton;
