import EmailButton from "./EmailButton";
import WebsiteButton from "./WebsiteButton";
import SocialMediaButton from "./SocialMediaButton";
import WhatsAppButton from "./WhatsAppButton";
import MapsButton from "./MapsButton";
import VcfButton from "./VcfButton";
import ShareButton from "./ShareButton";
import PhoneButton from "./PhoneButton";

const Button = ({ type, value, buttonColors, onClick, vcfFileName }) => {
  let buttonToRender = null;
  switch (type) {
    case "website":
      buttonToRender = value ? (
        <WebsiteButton website={value} buttonColors={buttonColors} />
      ) : (
        ""
      );
      break;
    case "location":
      buttonToRender = value ? (
        <MapsButton location={value} buttonColors={buttonColors} />
      ) : (
        ""
      );
      break;
    case "phone":
      buttonToRender = value ? (
        <PhoneButton number={value} buttonColors={buttonColors} />
      ) : (
        ""
      );
      break;
    case "email":
      buttonToRender = value ? (
        <EmailButton email={value} buttonColors={buttonColors} />
      ) : (
        ""
      );
      break;
    case "facebook":
      buttonToRender = value ? (
        <SocialMediaButton
          platform={type}
          link={value}
          buttonColors={buttonColors}
        />
      ) : (
        ""
      );
      break;
    case "twitter":
      buttonToRender = value ? (
        <SocialMediaButton
          platform={type}
          link={value}
          buttonColors={buttonColors}
        />
      ) : (
        ""
      );
      break;
    case "instagram":
      buttonToRender = value ? (
        <SocialMediaButton
          platform={type}
          link={value}
          buttonColors={buttonColors}
        />
      ) : (
        ""
      );
      break;
    case "tiktok":
      buttonToRender = value ? (
        <SocialMediaButton
          platform={type}
          link={value}
          buttonColors={buttonColors}
        />
      ) : (
        ""
      );
      break;
    case "linkedin":
      buttonToRender = value ? (
        <SocialMediaButton
          platform={type}
          link={value}
          buttonColors={buttonColors}
        />
      ) : (
        ""
      );
      break;
    case "whatsapp":
      buttonToRender = (
        <WhatsAppButton data={value} buttonColors={buttonColors} />
      );
      break;
    case "share":
      buttonToRender = (
        <ShareButton onClick={onClick} buttonColors={buttonColors} />
      );
      break;
    case "vcfFile":
      buttonToRender = (
        <VcfButton
          file={value}
          fileName={vcfFileName}
          buttonColors={buttonColors}
        />
      );
      break;

    default:
      break;
  }
  return buttonToRender;
};

export default Button;
