import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import ContactInfo from "../card/ContactInfo";
import userData from "../../assets/sample-data.json";
import "./userProfile.css";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams(); // Get the slug from the URL

  const fetchUser = (slug) => userData.find((user) => user.slug === slug); // Filter the user data

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // const response = await fetch('https://api.example.com/user');
        // const data = await response.json();
        const data = await fetchUser(slug);
        setUser(data);
        document.title = `${data.name} - Tarjeta Ejecutiva Digital`;
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="card-container">
      <Card key={user.id}>
        <CardHeader
          banner={user.banner}
          avatar={user.avatar}
          name={user.name}
          jobPosition={user.jobPosition}
          colors={user.colors}
        />
        <ContactInfo
          buttons={user.buttons}
          slogan={user.slogan}
          slug={user.slug}
          colors={user.colors}
        />
        {/* <CardFooter /> */}
      </Card>
    </div>
  );
};

export default UserProfile;
