import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const WebsiteButton = ({ website, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  return (
    <a
      href={website}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-button"
      style={buttonStyle}
    >
      <FontAwesomeIcon icon={faGlobe} style={iconStyle} />
    </a>
  );
};

export default WebsiteButton;
