import React from "react";
import "./shareModal.css";

const ShareModal = ({ image, toggleModal, isOpen, link }) => {
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleShareToWhatsApp = () => {
    const message = `Mira este perfil: ${link}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      {isOpen && (
        <div
          className={`modal ${isOpen ? "show" : "hide"}`}
          onClick={toggleModal}
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={toggleModal}>
              &times;
            </span>
            <h2 className="modal-title" style={{ textAlign: "center" }}>
              Compartir
            </h2>
            <img className="modal-image" src={image} alt="Modal" />
            <p style={{ textAlign: "center" }}>{link}</p>
            <button className="modal-button copy-link" onClick={handleCopyLink}>
              Copiar Link
            </button>
            <button
              className="modal-button share-to-whatsapp"
              onClick={handleShareToWhatsApp}
            >
              Compartir en WhatsApp
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareModal;
