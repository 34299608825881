import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const VcfButton = ({ file, fileName, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  const handleDownload = async () => {
    const response = await fetch(file);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // You can customize the filename here
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url); // Clean up the URL object
  };

  return (
    <button
      onClick={handleDownload}
      className="icon-button"
      style={buttonStyle}
    >
      <FontAwesomeIcon icon={faUserPlus} style={iconStyle} />
    </button>
  );
};

export default VcfButton;
