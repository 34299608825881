import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserProfile from "./components/userProfile/UserProfile";
// import LandingPage from "./components/landing/LandingPage";
import "./app.css";

const App = () => {
  useEffect(() => {
    document.body.classList.add(
      "text-neutral-900",
      "dark:text-neutral-200",
      "bg-white",
      "dark:bg-neutral-800"
    );

    // Cleanup function to remove the classes when the component unmounts
    return () => {
      document.body.classList.remove(
        "text-neutral-900",
        "dark:text-neutral-200",
        "bg-white",
        "dark:bg-neutral-800"
      );
    };
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<div>Sorry, this is just a temporal DEMO page.</div>}
        />
        <Route path="/:slug" element={<UserProfile />} />
        {/* Add other routes here */}
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </Router>
  );
};

export default App;
