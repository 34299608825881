import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const WhatsAppButton = ({ data, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  const { number, message } = data;
  const handleClick = () => {
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <button className="icon-button" onClick={handleClick} style={buttonStyle}>
      <FontAwesomeIcon icon={faWhatsapp} style={iconStyle} />
    </button>
  );
};

export default WhatsAppButton;
