import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";

const ShareButton = ({ onClick, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  return (
    <button className="icon-button" onClick={onClick} style={buttonStyle}>
      <FontAwesomeIcon icon={faShareAlt} style={iconStyle} />
    </button>
  );
};

export default ShareButton;
