import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const PhoneButton = ({ number, buttonColors }) => {
  const buttonStyle = {
    backgroundColor: buttonColors.primary,
  };
  const iconStyle = {
    color: buttonColors.icon,
  };
  const handleClick = () => {
    window.location.href = `tel:${number}`;
  };

  return (
    <button className="icon-button" onClick={handleClick} style={buttonStyle}>
      <FontAwesomeIcon icon={faPhone} style={iconStyle} />
    </button>
  );
};

export default PhoneButton;
